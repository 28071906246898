<script setup>
// imports
import { reactive, ref } from 'vue';
import { useStore } from '@/store/index.js';
import { useLogisticsStore } from '@/store/logistics.js';
import { useRouter } from 'vue-router'

// Components
import ValidationErrorMessages from '@/components/Ui/ValidationErrorMessages.vue';

// Consts
import GeneralConsts from '@/consts/GeneralConsts'

// store, router
const store = useStore();
const logisticsStore = useLogisticsStore();
const router = useRouter();

// props
const props = defineProps({
  fileTypeId: String
})

// 
const loading = ref(false);
const uploadFile = ref(null);
const validationErrorData = reactive({
  uploadFile: []
});

// 
const fileSelected = (event) => {
  event.preventDefault();
  const files = event.target.files;
  uploadFile.value = files[0];
  // this.$forceUpdate();
}
const upload = () => {
  // 
  loading.value = true;

  // 
  const formData = new FormData();
  formData.append("file_type_id", props.fileTypeId);
  formData.append("upload_file", uploadFile.value);
  const config = { headers: { "content-type": "multipart/form-data" } };
  axios
    .post(`${store.rootUrl}/api_drrGuide/logistics/upload-file`, formData, config)
    .then((response) => {
      console.log(response);
      if (response.data.status === 200) {
        alert("アップロード完了しました。");
        router.go(-1);
      } else if (response.data.status === 400) {
        validationErrorData.uploadFile = response.data.validationErrorData.uploadFile;
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
      loading.value = false;
    })
    .catch(function (error) {
      console.log(error);
      loading.value = false;
    });
}
</script>

<template>
  <el-container v-loading="loading" class="logistics-home-link-buttons">
    <el-row>
      <el-col>
        <div class="button-cover">
          <div>
            <input v-on:change="fileSelected($event)" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv">
            <ValidationErrorMessages :errorMessageList="validationErrorData.uploadFile" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="button-cover">
          <el-button @click="upload" type="primary" size="large">アップロード</el-button>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<style lang="scss" scoped></style>