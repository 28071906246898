//
//普通のお客さんが見るであろう画面類
//

let subDescription = "｜ドクターリセラパーフェクトガイド";

export default {
    routes: [
        /*
         *  通常のお客さんが見る画面
         */
        {
            path: '/',
            name: 'Top',
            component: () => import('@/views/Top.vue'),
            meta: {
                title: 'ドクターリセラパーフェクトガイド【伏見嘉洋】',
                description: 'ドクターリセラの使い手の頂点に立つダイヤモンドマイスター伏見嘉洋によるドクターリセラ製品の使い方を学び、購入できる通販サイトです。あなたの肌改善を実現します。ピュアモイスチャークレンジング、ソープ、日焼け止めなどスキンケアアイテムを取り揃えています。ポイント還元実施中。',
            },
        },

        {
            path: '/category/:cateId',
            name: 'Category',
            component: () => import('@/views/Items/Category.vue'),
            meta: {
                title: '商品カテゴリ別' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのカテゴリページです。',
            },
        },
        {
            path: '/itemdetails/:itemId/:categoryId',
            name: 'ItemDetail',
            component: () => import('@/views/Items/ItemDetail.vue'),
            meta: {
                title: '商品詳細' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドの商品詳細です。',
            },
        },
        {
            path: '/company',
            name: 'Company',
            component: () => import('@/views/Company.vue'),
            meta: {
                title: '会社概要' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドの会社概要です。',
            },
        },
        {
            path: '/qa',
            name: 'Qa',
            component: () => import('@/views/Qa.vue'),
            meta: {
                title: 'よくある質問' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのよくある質問とその回答です。',
            },
        },
        {
            path: '/tokutei',
            name: 'Tokutei',
            component: () => import('@/views/Tokutei.vue'),
            meta: {
                title: '特定商取引法に基づく表示' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドの特定商取引法に基づく表示です。',
            },
        },
        {
            path: '/guide',
            name: 'Guide',
            component: () => import('@/views/Guide.vue'),
            meta: {
                title: 'ご利用ガイド' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのご利用ガイドです。',
            },
        },
        {
            path: '/password',
            name: 'Password',
            component: () => import('@/views/PassReissue.vue'),
            meta: {
                title: 'パスワード再発行' + subDescription,
                description: 'パスワード再発行',
            },
        },
        //お問い合わせ
        {
            path: '/contact',
            name: 'Contact',
            component: () => import('@/views/Contact/Contact.vue'),
            meta: {
                title: 'お問い合わせ' + subDescription,
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのお問い合わせ窓口です。',
            },
        },
        //お問い合わせ内容確認
        {
            path: '/contact/confirm' + subDescription,
            name: 'ContactConfirm',
            component: () => import('@/views/Contact/ContactConfirm.vue'),
            meta: {
                title: 'お問い合わせ内容確認',
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのお問い合わせ窓口です。',
            },
        },
        //お問い合わせ完了
        {
            path: '/contact/complete' + subDescription,
            name: 'ContactComplete',
            component: () => import('@/views/Contact/ContactComplete.vue'),
            meta: {
                title: 'お問い合わせ完了',
                description: 'ドクターリセラダイヤモンドマイスターの伏見嘉洋が運営するドクターリセラパーフェクトガイドのお問い合わせ窓口です。',
            },
        },

        //
        //新規会員登録
        //
        {
            path: '/newmember',
            name: 'NewMember',
            component: () => import('@/views//NewMember/NewMember.vue'),
            meta: {
                title: '新規会員登録' + subDescription,
                description: '',
            },
        },
        {
            path: '/newmember/confirm',
            name: 'NewMemberConfirm',
            component: () => import('@/views//NewMember/NewMemberConfirm.vue'),
            meta: {
                title: '新規会員登録 入力確認' + subDescription,
                description: '',
            },
        },
        {
            path: '/newmember/checkaquaads',
            name: 'CheckAquaAds',
            component: () => import('@/views/NewMember/2402/CheckAquaAds.vue'),
            meta: {
                title: '新規会員登録・アクアADS判定' + subDescription,
                description: '',
            },
        },
        {
            path: '/newmember/complete',
            name: 'Complete',
            component: () => import('@/views/NewMember/Complete.vue'),
            meta: {
                title: '最終確認' + subDescription,
                description: '',
            },
        },
        {
            path: '/newmember/emailauthend',
            name: 'EmailAuthEnd',
            component: () => import('@/views/NewMember/EmailAuthEnd.vue'),
            meta: {
                title: 'Email確認完了' + subDescription,
                description: '',
            },
        },

        //
        //カート
        //
        {
            path: '/cart',
            name: 'Cart',
            component: () => import('@/views/Cart/Cart.vue'),
            meta: {
                title: 'カート' + subDescription,
                description:
                    '現在のカート内容を表示します。送信先入力画面へ遷移可能です。',
            },
        },
        {
            path: '/cart/destination',
            name: 'InputDestination',
            component: () => import('@/views/Cart/InputDestination.vue'),
            meta: {
                title: '送り先入力' + subDescription,
                description: '商品の送り先を入力・選択します',
            },
        },

        {
            path: '/cart/point',
            name: 'CartUsePoint',
            component: () => import('@/views/Cart/ChumonUsePoint.vue'),
            meta: {
                title: 'ポイント利用' + subDescription,
                description: '注文に使用するポイントの入力',
            },
        },
        // {
        //     path: '/cart/confirm',
        //     name: 'CartConfirm',
        //     component: () => import('@/views/Cart/ChumonConfirm.vue'),
        //     meta: {
        //         title: '最終確認' + subDescription,
        //         description: '注文の最終確認',
        //     },
        // },
        {
            path: '/cart/payment',
            name: 'CartPayment',
            component: () => import('@/views/Cart/ChumonPayment.vue'),
            meta: {
                title: '支払情報' + subDescription,
                description: '注文の支払いを入力します',
            },
        },
        {
            path: '/cart/complete',
            name: 'CartComplete',
            component: () => import('@/views/Cart/ChumonComplete.vue'),
            meta: {
                title: '注文完了' + subDescription,
                description: '注文を完了しました',
            },
        },

         //
        //１番以外のデータを削除
        //deleteDataOtherThan1
        //
        {
            path: '/deleteDataOtherThan1',
            name: 'deleteDataOtherThan1',
            component: () => import('@/views/Ctrl/deleteDataOtherThan1.vue'),
            meta: {
                title: 'データ削除',
                description: 'データ削除',
            },
        }
    ]
};