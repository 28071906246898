<script setup>
// imports
import { useStore } from '@/store/index.js';
import { useRouter } from 'vue-router'
import axios from 'axios'

// Consts
import GeneralConsts from "@/consts/GeneralConsts";
import AuthConsts from "@/consts/AuthConsts";

// store, router
const store = useStore();
const router = useRouter();

// 
const props = defineProps({
  authType: String
})

// store
// 

// methods
const logout = () => {
  // 
  let params = new URLSearchParams();
  params.append("auth_type", props.authType);
  axios
    .post(`${store.rootUrl}/api_drrGuide/auth/logout`, params)
    .then(response => {
      console.log(response);
      if (response.data.status === 200) {
        // 
        alert("ログアウトしました。");

        // 
        if (props.authType === AuthConsts.TYPE_LOGISTICS) {
          // 個人情報の初期化
          // store.dispatch('company/initializeData');
          // store.dispatch('company/initializeWithTrashedShopList');
          // store.dispatch('company/initializeWithTrashedEmployeeList');

          // 遷移
          router.push(`/logistics/login`);
        } else {
          alert('不正な authType です。');
        }
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
      return;
    })
    .catch(error => {
      console.log(error);
    });
}
</script>

<template>
  <el-button @click="logout()" type="info">ログアウト</el-button>
</template>

<style lang="scss" scoped></style>