export default Object.freeze({
    // 
    UPLOAD_TYPE_AUTO: '0',
    UPLOAD_TYPE_MANUAL: '1',

    // 
    FILE_STATUS_ID_CONFIRMING: '1',
    FILE_STATUS_ID_COMPLETE: '9',

    // 
    FILE_TYPE_ID_PRODUCT_MASTER: '1',
    FILE_TYPE_ID_SHIPPING_ORDER: '2',
    FILE_TYPE_ID_ARRIVAL_NOTICE: '3',
    FILE_TYPE_ID_INVENTORY_REPORT: '4',
    FILE_TYPE_ID_SHIPPING_REPORT: '5',
    FILE_TYPE_ID_ARRIVAL_REPORT: '6',
    FILE_TYPE_ID_OUT_OF_STOCK_REPORT: '7',
    FILE_TYPE_ID_EXCEPTIONAL_SHIPPING_ORDER: '8',
});
