<script setup>
// imports
import { reactive } from 'vue';
import { useStore } from '@/store/index.js';
import { useLogisticsStore } from '@/store/logistics.js';
import { useRouter } from 'vue-router'
import axios from 'axios'

// Components
import ValidationErrorMessages from '@/components/Ui/ValidationErrorMessages.vue';

// Consts
import GeneralConsts from "@/consts/GeneralConsts";
import AuthConsts from "@/consts/AuthConsts";

// store, router
const store = useStore();
const logisticsStore = useLogisticsStore();
const router = useRouter();

// 
const props = defineProps({
  authType: String
})

// store
// 

// 
const loginData = reactive({
  email: '',
  password: '',
});
const validationErrorData = reactive({
  authType: [],
  email: [],
  password: [],
});

// 
const login = () => {
  // 
  let params = new URLSearchParams();
  params.append("auth_type", props.authType);
  params.append("email", loginData.email);
  params.append("password", loginData.password);
  axios
    .post(`${store.rootUrl}/api_drrGuide/auth/login`, params)
    .then(response => {
      console.log(response);
      if (response.data.status === 200) {
        // 
        if (props.authType === AuthConsts.TYPE_LOGISTICS) {
          // storeにセット
          logisticsStore.setCompanyData({ companyData: response.data.data });
          logisticsStore.setCompanyList();

          // 遷移
          router.push(`/logistics`);
        } else {
          alert('不正なauthTypeです。');
        }
      } else if (response.data.status === 400) {
        for (const columnName in validationErrorData) {
          if (response.data.validationErrorData[columnName] === undefined) {
            validationErrorData[columnName] = [];
          } else {
            validationErrorData[columnName] = response.data.validationErrorData[columnName];
          }
        }
      } else if (response.data.status === 401) {
        for (const columnName in validationErrorData) {
          validationErrorData[columnName] = [];
        }
        alert(response.data.message);
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
      return;
    })
    .catch(error => {
      console.log(error);
    });
}
</script>

<template>
  <el-container class="login-form">
    <el-row type="flex" justify="center" class="inputs">
      <el-col :span="10">
        <el-input v-model="loginData.email" size="large" placeholder="info@example.co.jp">
          <template #prepend>ログインID</template>
        </el-input>
        <ValidationErrorMessages :errorMessageList="validationErrorData.email" />
        <el-input v-model="loginData.password" type="password" class="password" size="large" placeholder="************">
          <template #prepend>パスワード</template>
        </el-input>
        <ValidationErrorMessages :errorMessageList="validationErrorData.password" />
        <ValidationErrorMessages :errorMessageList="validationErrorData.authType" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="button-cover">
          <el-button @click="login()" type="primary" size="large">ログイン</el-button>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<style lang="scss" scoped>
.el-row {
  &.inputs {
    .el-input {
      &.password {
        margin-top: 10px;
      }
    }
  }
}
</style>