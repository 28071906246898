<script setup>
// imports
import { ref } from 'vue';
import { useLogisticsStore } from '@/store/logistics.js';
import { useRouter } from 'vue-router'

// store, router
const logisticsStore = useLogisticsStore();
const router = useRouter();

// Consts
import AuthConsts from "@/consts/AuthConsts";

// Components
import LogoutButton from '@/components/Ui/LogoutButton.vue';
import OutOfStockAlert from '@/components/Logistics/Home/OutOfStockAlert.vue';

// 
const fileTypeList = ref(logisticsStore.getFileTypeList);
</script>

<template>
  <el-container class="home-contents">
    <el-row>
      <el-col>
        <div>{{ logisticsStore.getCompanyData.name }} 様</div>
      </el-col>
    </el-row>
    <OutOfStockAlert />
    <el-row :gutter="20">
      <el-col v-for="(fileTypeData, index) in fileTypeList" :key="index" :span="6">
        <el-card @click="router.push(`/logistics/files/${fileTypeData.id}`)" shadow="hover">{{ fileTypeData.name
          }}</el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <LogoutButton :authType="AuthConsts.TYPE_LOGISTICS" />
      </el-col>
    </el-row>
  </el-container>
</template>

<style lang="scss" scoped>
.el-col {
  &:nth-child(n+5) {
    margin-top: 1em;
  }

  .el-card {
    cursor: pointer;
  }
}
</style>