<script setup>
// imports
import { useLogisticsStore } from '@/store/logistics.js';

// store, router
const logisticsStore = useLogisticsStore();

// Components
import FileList from "@/components/Logistics/Files/FileList.vue";

// props
const props = defineProps({
  fileTypeId: String
})

// 
const fileTypeData = logisticsStore.getFileTypeData(props.fileTypeId);
</script>

<template>
  <h1>{{ fileTypeData.name }} 一覧</h1>
  <FileList :fileTypeId="props.fileTypeId" />
</template>

<style lang="scss" scoped></style>