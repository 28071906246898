<template>
  <div class="footerFixed">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
:root {
  /* color */
  --red: #ff6568;
  --orange: #ee9200;
}

.footerFixed {
  min-height: 100vh;
  /* ←コンテンツの高さの最小値＝ブラウザの高さに指定 */
  position: relative;
  /* ←相対位置 */
  padding-bottom: 60px;
  /* ←フッターの高さを指定 */
  box-sizing: border-box;
}

.button-cover {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

// 
section {
  &#logistics {
    text-align: left;

    >.el-container {
      flex-direction: column;

      .el-main {
        padding: 0;
        display: flex;
        justify-content: center;

        .contents-cover {
          width: 1100px;
          padding: 50px;

          h1 {
            margin-top: 1em;
            text-align: center;
            font-size: 30px;
          }

          .el-container {
            display: block;
            margin: 30px 0;

            .el-row {
              & + .el-row {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
