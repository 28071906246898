// 
import Logistics from '@/views/Logistics.vue'
import LogisticsHome from '@/views/Logistics/Home.vue'
import LogisticsLogin from '@/views/Logistics/Login.vue'
import LogisticsFiles from '@/views/Logistics/Files.vue'
import LogisticsUpload from '@/views/Logistics/Upload.vue'

// 
import AuthConsts from '@/consts/AuthConsts'

// 
export default {
  routes: [
    {
      path: '/logistics',
      component: Logistics,
      children: [
        {
          path: '',
          name: 'logistics-home',
          component: LogisticsHome,
          meta: {
            requiresAuth: true,
            authType: AuthConsts.TYPE_LOGISTICS
          },
        },
        {
          path: 'login',
          name: 'logistics-login',
          component: LogisticsLogin,
          meta: {
            requiresAuth: false
          },
        },
        {
          path: 'files/:fileTypeId',
          name: 'logistics-files',
          component: LogisticsFiles,
          props: route => ({
            fileTypeId: String(route.params.fileTypeId)
          }),
          meta: {
            requiresAuth: true,
            authType: AuthConsts.TYPE_LOGISTICS
          },
        },
        {
          path: 'upload/:fileTypeId',
          name: 'logistics-upload',
          component: LogisticsUpload,
          props: route => ({
            fileTypeId: String(route.params.fileTypeId)
          }),
          meta: {
            requiresAuth: true,
            authType: AuthConsts.TYPE_LOGISTICS
          },
        },
      ]
    },
  ]
};