// 
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from "@/store/index.js";
import { useLogisticsStore } from "@/store/logistics.js";
import isMobile from "ismobilejs";

// 
import AuthConsts from "@/consts/AuthConsts";

//
import { setTitle, setDescription } from "./set_title_description.js";

//
import RouteNormal from "./route.js";
import RouteCtrl from "./route_ctrl.js";
import RouteLogistics from "./route_logistics.js";
import RouteRecella from "./route_recella.js";
import RouteMyPage from "./route_mypage.js";
import RouteSankyo from "./route_sankyo.js";

//
const routes = [
  ...RouteNormal.routes,
  ...RouteCtrl.routes,
  ...RouteLogistics.routes,
  ...RouteRecella.routes,
  ...RouteMyPage.routes,
  ...RouteSankyo.routes,
];

// 
const router = createRouter({
  // history: createWebHistory("/ctrl/"),
  history: createWebHistory(),
  // history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
});

// 
router.beforeEach((to, from, next) => {
  // 
  const Store = useStore();
  const LogisticsStore = useLogisticsStore();

  // 
  var host = "https://drr-guide.com/storage";
  var api = "https://drr-guide.com";
  if (window.location.host === "drr-guide.com") {
    // no action.
  } else {
    // server_url = "http://drr-guide.localhost";
    host = "http://localhost/storage";
    api = "http://localhost";
    //ローカルである事をセット
    Store.setIsLocal(true);
  }

  // モバイルならviewportをスマホにセットし直す
  const userAgent = navigator.userAgent;
  var result = isMobile(userAgent);
  // "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no";
  let viewportContent = "width=1200";
  if (result.phone) {
    //SmartPhone
    viewportContent =
      "width=375,user-scalable=no,viewport-fit=cover";
    //Storeへコミット
    Store.setIsMobile(true);
  } else {
    Store.setIsMobile(false);
  }
  document
    .querySelector("meta[name='viewport']")
    .setAttribute("content", viewportContent);

  //
  //タイトル・デスクリプションセット
  //
  // setTitle(to.meta.title + " ｜ ドクターリセラパーフェクトガイド　by伏見嘉洋");
  setTitle(to.meta.title);
  setDescription(to.meta.description);

  // 
  Promise.all([
    Store.setHost(host),
    Store.setApi(api),
    Store.setWholeCampaignData(),
    LogisticsStore.setFileTypeList(),
    LogisticsStore.setFileStatusList()
  ]).then(function () {
    if (to.meta.requiresAuth === true) {
      axios
        .get(`${Store.rootUrl}/api_drrGuide/auth/check?auth_type=${to.meta.authType}`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            next();
          } else {
            if (to.meta.authType === AuthConsts.TYPE_LOGISTICS) {
              next('/logistics/login');
            } else {
              next('/');
            }
          }
        })
        .catch(error => {
          console.log(error);
          if (to.meta.authType === AuthConsts.TYPE_LOGISTICS) {
            next('/logistics/login');
          } else {
            next('/');
          }
        });
    } else {
      next();
    }
  });
});

// 
export default router;
