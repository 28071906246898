<script setup>
</script>

<template>
  <el-footer>
    <div class="footer-inner">
      <p>Copyright © ドクターリセラパーフェクトガイド All Rights Reserved.</p>
    </div>
  </el-footer>
</template>

<style lang="scss" scoped>
.el-footer {
  background-color: var(--orange);
  color: white;
  .footer-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>