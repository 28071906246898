<script setup>
// imports
import { ref, computed, onMounted } from 'vue';
import { useStore } from '@/store/index.js';
import { useLogisticsStore } from '@/store/logistics.js';
import { useRouter } from 'vue-router'

// Consts
import LogisticsConsts from "@/consts/LogisticsConsts";

// store, router
const store = useStore();
const logisticsStore = useLogisticsStore();
const router = useRouter();

// 
const outOfStockReportFileList = ref([]);

// 
const confirmingOutOfStockReportFileExists = computed(() => {
  // 
  let confirmingOutOfStockReportFileExists = false;
  for (const outOfStockReportFileData of outOfStockReportFileList.value) {
    if (outOfStockReportFileData.logisticsFileStatusId === LogisticsConsts.FILE_STATUS_ID_COMPLETE) {
      // no action.
    } else {
      confirmingOutOfStockReportFileExists = true;
      break;
    }
  }

  // 
  return confirmingOutOfStockReportFileExists;
})

// 
onMounted(() => {

  // 
  axios
    .get(`${store.rootUrl}/api_drrGuide/logistics/get-file-list?file_type_id=${LogisticsConsts.FILE_TYPE_ID_OUT_OF_STOCK_REPORT}`)
    .then((response) => {
      console.log(response);

      // 
      if (response.data.status === 200) {
        outOfStockReportFileList.value = response.data.list;
      } else if (response.data.status === 401) {
        router.push('/logistics/login');
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
})
</script>

<template>
  <el-row v-if="confirmingOutOfStockReportFileExists === true">
    <el-col>
      <el-alert @click="router.push('/logistics/files/7')" title="未対応の欠品報告Excelがあります。" type="error" center show-icon />
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.el-alert {
  cursor: pointer;
}
</style>