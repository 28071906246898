// 
import { defineStore } from "pinia";
import { useStore } from "@/store/index.js";

// 
export const useLogisticsStore = defineStore('logistics', {
  state: () => ({
    companyData: null,
    companyList: [],
    fileTypeList: [],
    fileStatusList: [],
  }),
  getters: {
    getCompanyData() {
      return this.companyData;
    },
    getCompanyDataFromCompanyList: (state) => {
      return (id) => state.companyList.find(companyData => companyData.id === id);
    },
    getFileTypeList() {
      return this.fileTypeList;
    },
    getFileTypeData: (state) => {
      return (fileTypeId) => state.fileTypeList.find(fileTypeData => fileTypeData.id === fileTypeId);
    },
    getFileStatusList() {
      return this.fileStatusList;
    },
  },
  actions: {
    setCompanyData(payload) {
      this.companyData = payload.companyData;
    },
    setCompanyList() {
      // 
      const store = useStore();

      // 
      axios
        .get(`${store.rootUrl}/api_drrGuide/company/get-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            this.companyList = response.data.list;
          } else {
            alert('error in setCompanyList.');
          }
          return;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setFileTypeList() {
      // 
      const store = useStore();

      // 
      axios
        .get(`${store.rootUrl}/api_drrGuide/logistics/get-file-type-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            this.fileTypeList = response.data.list;
          } else {
            alert('error in setFileTypeList.');
          }
          return;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setFileStatusList() {
      // 
      const store = useStore();

      // 
      axios
        .get(`${store.rootUrl}/api_drrGuide/logistics/get-file-status-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            this.fileStatusList = response.data.list;
          } else {
            alert('error in setFileStatusList.');
          }
          return;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  persist: {
    enabled: true,
    strategies: [{
      key: "drr",
      storage: sessionStorage,
    },],
  },
});
