<script setup>
// imports
import { useRouter } from 'vue-router'

// Consts
import AuthConsts from "@/consts/AuthConsts";

// store, router
const router = useRouter();

// 
const props = defineProps({
  authType: String
})

// 
const toTop = () => {
  // 
  let path = '/';
  switch (props.authType) {
    case AuthConsts.TYPE_LOGISTICS:
      path = '/logistics';
      break;

    default:
      // no action.
      break;
  }

  // 
  router.push(path);
}
</script>

<template>
  <el-header>
    <div class="header-inner">
      <div class="header-left">
        <div @click="toTop()" class="site-title">
          <span v-if="props.authType === AuthConsts.TYPE_LOGISTICS">Dr.Recella 物流EDI</span>
        </div>
      </div>
      <div class="header-right"></div>
    </div>
  </el-header>
</template>

<style lang="scss" scoped>
.el-header {
  background-color: var(--orange);
  color: white;

  .header-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      .site-title {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: '游明朝', 'Yu Mincho', YuMincho, 'Hiragino Mincho Pro', serif;
        cursor: pointer;
      }
    }
  }
}
</style>